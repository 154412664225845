<template>
  <div class="history">
    <header class="history-header">
      <div class="item">
        <span>修改时间：</span>
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          placeholder="请选择时间"
        ></el-date-picker>
      </div>
      <el-button type="primary" @click="getList">查询</el-button>
      <el-button type="primary" @click="reset">重置</el-button>
    </header>
    <DragTable :header="header" :list-query="list">
      <template #create_time="{row}">{{$dayjs(row.create_time*1000).format('YYYY-MM-DD HH:mm:ss')}}</template>
      <template #obj="{row}">
        <p v-for="(item,i) in row.operation" :key="i" style="border-bottom: 1px solid;line-height: 20px;height: 20px;">{{item.field}}</p>
      </template>
      <template #before="{row}">
        <p v-for="(item,i) in row.operation" :key="i" style="border-bottom: 1px solid;line-height: 20px;height: 20px;">{{item.origin}}</p>
      </template>
      <template #after="{row}">
        <p v-for="(item,i) in row.operation" :key="i" style="border-bottom: 1px solid;line-height: 20px;height: 20px;">{{item.new}}</p>
      </template>
    </DragTable>
    <footer class="table-footer">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="params.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="params.total"
      >
      </el-pagination>
    </footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      date: [],
      header: [
        {name:'修改时间', slot: 'create_time'},
        {name:'操作人', value: 'operator'},
        {name:'操作对象', slot: 'obj'},
        {name:'变更前', slot: 'before'},
        {name:'变更后', slot: 'after'},
      ],
      list: {
        data: []
      },
      params: {
        page: 1,
        limit: 10,
        create_time_start: '',
        create_time_end: '',
        total: 0
      }
    };
  },
  watch: {
    date(val) {
      if(val) {
        this.params.create_time_start = this.$dayjs(val[0]).startOf('day').unix()
        this.params.create_time_end = this.$dayjs(val[1]).endOf('day').unix()
      } else {
        this.params.create_time_start = ''
        this.params.create_time_end = ''
      }
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.$axios.get(`/configCenter/handlingFee/operation/list`,{
        params: this.params
      })
      .then(res=>{
        this.list.data = res.data.data
        this.params.total = res.data.total
      })
    },
    reset() {
      for(let key in this.params) {
        this.params[key] = ''
      }
      this.params.page = 1
      this.params.limit = 10
      this.date = []
      this.getList()
    },
    handleSizeChange(val){
      this.params.limit = val;
      this.params.page = 1
      this.getList();
    },
    handleCurrentChange(val){
      this.params.page = val
      this.getList();
    }
  }
};
</script>
<style lang="scss" scoped>
.history-header{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  &>div{
    margin-right: 10px;
  }
}
</style>